<template>
  <v-container class="my-5">
    <!-- open issues -->
    <v-row>
      <v-col cols="12">
        <v-toolbar>
          <v-toolbar-title class="pr-8">Pending resolutions</v-toolbar-title>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                :loading="loading"
                :disabled="loading"
                color="primary darken-1"
                text
                @click="getResolutions()"
                icon
                v-on="on"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Refresh data now</span>
          </v-tooltip>

          <!-- approval all -->
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                :loading="loading"
                :disabled="loading"
                color="primary darken-1"
                text
                icon
                @click="approveAll()"
                v-on="on"
              >
                <v-icon>mdi-check-decagram</v-icon>
              </v-btn>
            </template>
            <span>Approve all pending resolutions</span>
          </v-tooltip>

          <!-- reject all -->
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                :loading="loading"
                :disabled="loading"
                color="primary darken-1"
                text
                icon
                @click="rejectAll()"
                v-on="on"
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </template>
            <span>Reject all pending resolutions</span>
          </v-tooltip>

          <!-- clear all -->
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                :loading="loading"
                :disabled="loading"
                text
                icon
                color="primary darken-1"
                @click="clearAll()"
                v-on="on"
              >
                <v-icon>mdi-cached</v-icon>
              </v-btn>
            </template>
            <span>Clear status on all pending resolutions</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                :loading="loading"
                :disabled="loading"
                color="primary darken-1"
                text
                icon
                @click="processResolutions()"
                v-on="on"
              >
                <v-icon class="mr-2">mdi-application-cog</v-icon>
              </v-btn>
            </template>
            <span>Post and process resolutions</span>
          </v-tooltip>

          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>

          <v-progress-linear
            :active="!!loading"
            :indeterminate="true"
            absolute
            bottom
            color="blue-grey darken-3"
          ></v-progress-linear>
        </v-toolbar>

        <v-card>
          <v-data-table
            :headers="headers"
            :search="search"
            :items="resolutions"
            :items-per-page="10"
            class="elevation-1"
            :sort-by.sync="resolutionsSortBy"
            :sort-desc.sync="resolutionsSortDesc"
            dense
            multi-sort
            item-key="sale_processing_issue_resolution_id"
            @click:row="rowClick"
            :item-class="getItemClass"
          >
            <template v-slot:item.userInfo="{ item }">
              {{ getUserInfo(item) }}
            </template>

            <template v-slot:item.extCustID="{ item }">
              {{ getExternalCustomerID(item) }}
            </template>

            <template v-slot:item.extCustName="{ item }">
              {{ getExternalCustomerName(item) }}
            </template>

            <template v-slot:item.warehouseID="{ item }">
              {{ getWarehouseID(item) }}
            </template>

            <template v-slot:item.warehouseName="{ item }">
              {{ getWarehouseName(item) }}
            </template>

            <template v-slot:item.warehouseMarketSegmentID="{ item }">
              {{ getWarehouseMarketSegmentID(item) }}
            </template>

            <template
              v-slot:item.warehouseMarsDirectSalesTerritoryID="{ item }"
            >
              {{ getWarehouseMarsDirectSalesTerritoryID(item) }}
            </template>

            <template v-slot:item.warehouseSubgroupID="{ item }">
              {{ getWarehouseSubgroupID(item) }}
            </template>

            <template v-slot:item.warehouseSubchannelID="{ item }">
              {{ getWarehouseSubchannelID(item) }}
            </template>

            <template v-slot:item.pipelineName="{ item }">
              {{ getPipelineName(item) }}
            </template>

            <template v-slot:item.status="{ item }">
              <v-chip
                small
                v-if="showStatus(item.status)"
                :color="getStatusColor(item.status)"
                dark
              >
                {{ item.status }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <!-- resolution actions -->
        <v-toolbar elevation="1">
          <v-toolbar-title class="pr-8">Resolution info</v-toolbar-title>

          <!-- show sales button -->
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                :disabled="!lastClickedItem"
                :loading="loading"
                color="primary darken-1"
                text
                @click="showPendingSaleClick()"
                icon
                v-on="on"
              >
                <v-icon>mdi-point-of-sale</v-icon>
              </v-btn>
            </template>
            <span>View pending sale info</span>
          </v-tooltip>

          <!-- approve button -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :disabled="!lastClickedItem"
                :loading="loading"
                color="primary darken-1"
                v-bind="attrs"
                v-on="on"
                @click="approve()"
              >
                <v-icon>mdi-check-decagram</v-icon>
              </v-btn>
            </template>
            <span>Approve</span>
          </v-tooltip>

          <!-- reject button -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :disabled="!lastClickedItem"
                :loading="loading"
                color="primary darken-1"
                v-bind="attrs"
                v-on="on"
                @click="reject()"
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </template>
            <span>Reject</span>
          </v-tooltip>

          <!-- reject button -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :disabled="!lastClickedItem"
                :loading="loading"
                v-bind="attrs"
                v-on="on"
                @click="clearStatus()"
                color="primary darken-1"
              >
                <v-icon>mdi-cached</v-icon>
              </v-btn>
            </template>
            <span>Clear status</span>
          </v-tooltip>
        </v-toolbar>
      </v-col>
    </v-row>

    <div v-if="!!lastClickedItem">
      <v-row>
        <v-col cols="3" class="ml-4 mt-0 mr-4">
          <v-text-field
            :value="getResolutionTypeText()"
            label="Resolution type"
            readonly
          ></v-text-field>
        </v-col>

        <v-col cols="3" class="ml-4 mt-0 mr-4">
          <v-text-field
            :value="lastClickedItem.data.email || 'system'"
            label="User"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- create mapping data -->
      <div v-if="isCustomerMapping()">
        <v-row>
          <v-col cols="1" class="ml-4 mt-0 mr-4">
            <v-text-field
              :value="lastClickedItem.data.pipeline_id"
              label="Pipeline"
              readonly
            ></v-text-field>
          </v-col>

          <v-col cols="2" class="ml-4 mt-0 mr-4">
            <v-text-field
              :value="lastClickedItem.data.customer_id"
              label="Customer ID"
              readonly
            ></v-text-field>
          </v-col>

          <v-col cols="2" class="ml-4 mt-0 mr-4">
            <v-text-field
              :value="lastClickedItem.data.mars_vendor_warehouse_id"
              label="Warehouse ID"
              readonly
              append-outer-icon="mdi-open-in-new"
              @click:append-outer="openWarehouse()"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-card>
              <v-toolbar dense color="primary">
                <v-toolbar-title>Sale's record data</v-toolbar-title>
              </v-toolbar>

              <div v-if="!!pendingSale">
                <v-row class="ml-4">
                  <v-col cols="2">
                    <v-text-field
                      :value="pendingSale.customer_id"
                      label="Customer ID"
                      readonly
                    ></v-text-field>
                  </v-col>

                  <v-col cols="8">
                    <v-text-field
                      :value="pendingSale.customer_name"
                      label="Name"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="ml-4">
                  <v-col cols="2">
                    <v-text-field
                      :value="pendingSale.house_number"
                      label="House"
                      readonly
                    ></v-text-field>
                  </v-col>

                  <v-col cols="5">
                    <v-text-field
                      :value="pendingSale.address_1"
                      label="Address 1"
                      readonly
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      :value="pendingSale.address_2"
                      label="Address 2"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="ml-4">
                  <v-col cols="6">
                    <v-text-field
                      :value="pendingSale.city"
                      label="City"
                      readonly
                    ></v-text-field>
                  </v-col>

                  <v-col cols="1">
                    <v-text-field
                      :value="pendingSale.state"
                      label="State"
                      readonly
                    ></v-text-field>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field
                      :value="pendingSale.zip"
                      label="Zip"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <v-row class="mb-6" justify="center" no-gutters>
                  <v-col md="auto">
                    <p class="ma-6">No data found</p>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>

          <!-- Warehouse record data -->
          <v-col cols="6">
            <v-card>
              <v-toolbar dense color="primary">
                <v-toolbar-title>Warehouse record data</v-toolbar-title>
              </v-toolbar>

              <div v-if="!!mappedWarehouse">
                <v-row class="ml-4">
                  <v-col cols="2">
                    <v-text-field
                      :value="mappedWarehouse.id"
                      label="Warehouse ID"
                      readonly
                    ></v-text-field>
                  </v-col>

                  <v-col cols="8">
                    <v-text-field
                      :value="mappedWarehouse.name"
                      label="Name"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="ml-4">
                  <v-col cols="2">
                    <v-text-field
                      :value="mappedWarehouse.house_number"
                      label="House"
                      readonly
                    ></v-text-field>
                  </v-col>

                  <v-col cols="5">
                    <v-text-field
                      :value="mappedWarehouse.street"
                      label="Street"
                      readonly
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      :value="mappedWarehouse.street_2"
                      label="Street 2"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="ml-4">
                  <v-col cols="6">
                    <v-text-field
                      :value="mappedWarehouse.city"
                      label="City"
                      readonly
                    ></v-text-field>
                  </v-col>

                  <v-col cols="1">
                    <v-text-field
                      :value="mappedWarehouse.state"
                      label="State"
                      readonly
                    ></v-text-field>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field
                      :value="mappedWarehouse.zip"
                      label="Zip"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <v-row class="mb-6" justify="center" no-gutters>
                  <v-col md="auto">
                    <p class="ma-6">No data found</p>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <!-- create new warehouse -->
      <div v-if="isCreateWarehouse()">
        <div>
          <v-row>
            <v-col cols="8" class="ml-4 mt-0 mr-4">
              <v-text-field
                dense
                :value="lastClickedItem.data.name"
                readonly
                label="Name"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1" class="ml-4 mt-0 mr-4">
              <v-text-field
                dense
                :value="lastClickedItem.data.house_number"
                readonly
                label="House"
              ></v-text-field>
            </v-col>

            <v-col cols="3" class="ml-4 mt-0 mr-4">
              <v-text-field
                dense
                :value="lastClickedItem.data.street"
                readonly
                label="Street"
              ></v-text-field>
            </v-col>

            <v-col cols="3" class="ml-4 mt-0 mr-4">
              <v-text-field
                dense
                :value="lastClickedItem.data.street_2"
                readonly
                label="Street 2"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" class="ml-4 mt-0 mr-4">
              <v-text-field
                dense
                :value="lastClickedItem.data.city"
                readonly
                label="City"
              ></v-text-field>
            </v-col>

            <v-col cols="1" class="ml-4 mt-0 mr-4">
              <v-text-field
                dense
                :value="lastClickedItem.data.state"
                readonly
                label="State"
              ></v-text-field>
            </v-col>

            <v-col cols="1" class="ml-4 mt-0 mr-4">
              <v-text-field
                dense
                :value="lastClickedItem.data.zip"
                readonly
                label="ZIP"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3" class="ml-4 mt-0 mr-4">
              <v-text-field
                dense
                :value="marketSegment"
                readonly
                label="Market segment"
              ></v-text-field>
            </v-col>

            <v-col cols="3" class="ml-4 mt-0 mr-4">
              <v-text-field
                dense
                :value="buyingSubgroup"
                readonly
                label="Subgroup"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3" class="ml-4 mt-0 mr-4">
              <v-text-field
                dense
                :value="distributor"
                readonly
                label="Distributor"
              ></v-text-field>
            </v-col>

            <v-col cols="3" class="ml-4 mt-0 mr-4">
              <v-text-field
                dense
                :value="marsDirectSalesTerritory"
                readonly
                label="Direct sales territory"
              ></v-text-field>
            </v-col>

            <v-col cols="3" class="ml-4 mt-0 mr-4">
              <v-text-field
                dense
                :value="subchannel"
                readonly
                label="Subchannel"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>

    <v-row>
      <h4 class="ml-6 mt-4 text--red">
        Debugging info (will be removed later)
      </h4>
      <v-col cols="12" class="ml-4 mt-0 mr-4">
        <pre
          >{{ ResolutionDataAsString }}
        </pre>
      </v-col>
    </v-row>

    <PendingSale :pendingSale="pendingSale" v-model="pendingSaleDialog">
    </PendingSale>

    <confirm ref="confirm"></confirm>
  </v-container>
</template>

<script>
import api from "@/services/api";
import { formatMoney, formatDate, formatNumber } from "@/lib/utils";
import confirm from "@/components/utils/Confirm";
import PendingSale from "@/components/utils/PendingSale";

const REJECTED = "Rejected";
const APPROVED = "Approved";
const NO_STATUS = "";

export default {
  components: {
    confirm,
    PendingSale,
  },

  data() {
    return {
      resolutions: [],
      loading: false,
      search: "",
      selectedResolution: null,
      selected: [],
      lastClickedItem: null,
      processingResults: null,
      mappedWarehouse: null,

      resolutionsSortBy: ["total_extended_price"],
      resolutionsSortDesc: true,

      headers: [
        { text: "Resolution ID", value: "sale_processing_issue_resolution_id" },
        { text: "User", value: "userInfo", filterable: true },
        { text: "Status", value: "status" },
        { text: "Pipeline", value: "pipelineName", filterable: true },

        { text: "Ext Cust ID", value: "extCustID", filterable: true },
        { text: "Ext Cust Name", value: "extCustName", filterable: true },

        { text: "Warehouse ID", value: "warehouseID", filterable: true },
        { text: "Warehouse Name", value: "warehouseName" },
        { text: "Segment ID", value: "warehouseMarketSegmentID" },
        { text: "Territory ID", value: "warehouseMarsDirectSalesTerritoryID" },
        { text: "Subgroup ID", value: "warehouseSubgroupID" },
      ],

      pendingSaleDialog: false,

      pendingSale: {
        data: {},
        data_type: "",
        pipeline_name: "",
        pending_sale_id: "",
        import_batch_id: "",
      },
    };
  },

  methods: {
    getResolutions() {
      this.loading = true;
      this.search = "";
      const self = this;

      api
        .callDBFunc("etl-2", "get_pending_resolutions", {
          filters: [{ column_name: "failure_code_id", value: "CM" }],
        })
        .then((response) => {
          self.loading = false;

          let rows = response.data.rows;
          if (rows) {
            rows.forEach((r) => (r.status = NO_STATUS));
          }

          self.resolutions = rows || [];
        })
        .catch(() => {
          self.loading = false;
        });
    },

    formatMoney(value) {
      // stupid, but here so the linter doesn't complain
      return formatMoney(value);
    },

    formatNumber(value) {
      // stupid, but here so the linter doesn't complain
      return formatNumber(value);
    },

    formatDate(value) {
      // stupid, but here so the linter doesn't complain
      return formatDate(value);
    },

    rowClick(item) {
      this.lastClickedItem = item;
      this.selectedResolution = item;

      if (item.data?.sale_data) {
        this.pendingSale = item.data.sale_data;
      } else {
        this.clearPendingSale();
      }

      this.mappedWarehouse = item.data.mars_vendor_warehouse;
    },

    approve() {
      this.lastClickedItem.status = APPROVED;
    },

    reject() {
      this.lastClickedItem.status = REJECTED;
    },

    clearStatus() {
      this.lastClickedItem.status = NO_STATUS;
    },

    getItemClass(item) {
      if (
        this.lastClickedItem &&
        item.sale_processing_issue_resolution_id ===
          this.lastClickedItem.sale_processing_issue_resolution_id
      ) {
        return "highlightedRow";
      }

      return "";
    },

    isCustomerMapping() {
      return (
        this.lastClickedItem &&
        this.lastClickedItem.data &&
        this.lastClickedItem.data.type === "add_customer_mapping"
      );
    },

    isCreateWarehouse() {
      return (
        this.lastClickedItem &&
        this.lastClickedItem.data &&
        this.lastClickedItem.data.type === "create_warehouse"
      );
    },

    changeStatusAll(value) {
      this.resolutions.forEach((r) => (r.status = value));
    },

    rejectAll() {
      const self = this;

      this.$refs.confirm
        .open(
          "Confirm",
          `Are you sure want to reject all? This may include items not currently display in the table.`,
          {
            color: "#C62828",
            width: 500,
          }
        )
        .then((confirm) => {
          if (confirm) {
            self.changeStatusAll(REJECTED);
          }
        });
    },

    approveAll() {
      const self = this;

      this.$refs.confirm
        .open(
          "Confirm",
          `Are you sure want to approval all? This may include items not currently display in the table.`,
          {
            color: "#C62828",
            width: 500,
          }
        )
        .then((confirm) => {
          if (confirm) {
            self.changeStatusAll(APPROVED);
          }
        });
    },

    clearAll() {
      const self = this;

      this.$refs.confirm
        .open(
          "Confirm",
          `Are you sure want to clear all? This may include items not currently display in the table.`,
          {
            color: "#C62828",
            width: 500,
          }
        )
        .then((confirm) => {
          if (confirm) {
            self.changeStatusAll(NO_STATUS);
          }
        });
    },

    showStatus(status) {
      return status != NO_STATUS;
    },

    getStatusColor(status) {
      switch (status) {
        case APPROVED:
          return "green darken-3";
        case REJECTED: // foo is 0 so criteria met here so this block will run
          return "red darken-3";
        default:
          return "black";
      }
    },

    clearPendingSale() {
      this.pendingSale = {
        data: {},
        data_type: "",
        pipeline_name: "",
        pending_sale_id: "",
        import_batch_id: "",
      };
    },

    showPendingSaleClick() {
      this.pendingSaleDialog = true;
    },

    closePendingSaleClick() {
      this.pendingSaleDialog = false;
    },

    openWarehouse() {
      const w = this.lastClickedItem.data.mars_vendor_warehouse_id;

      if (w) {
        for (let i = 0; i < this.$router.options.routes.length; i++) {
          const route = this.$router.options.routes[i];
          if (route.name === "Warehouse") {
            let url = route.path.replace(":id", w.toString());
            window.open(url, "_blank");
          }
        }
      }
    },

    toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },

    getResolutionTypeText() {
      if (this.lastClickedItem) {
        return this.toTitleCase(
          this.lastClickedItem.data.type.replace(/_/g, " ")
        );
      } else {
        return "";
      }
    },

    updateResolutionResults(values, results) {
      // find the resolution in the resolutions array and update it
      // with the results from the processing
      for (let row of results) {
        values.find((r) => {
          if (
            r.resolution.sale_processing_issue_resolution_id ===
            row.sale_processing_issue_resolution_id
          ) {
            r.op_result = row.result;
          }
        });
      }
    },

    processResolutions() {
      let r = null;
      const self = this;


      let approvedValues = [];
      let rejectedValues = [];

      for (r of this.resolutions) {
        if (r.status === APPROVED) {
          approvedValues.push({
            resolution: r,
            status: "A",
          });
        } else if (r.status === REJECTED) {
          rejectedValues.push({
            resolution: r,
            status: "R",
          });
        }
      }

      if (rejectedValues.length > 0) {
        self.loading = true;

        api
          .callDBFunc(
            "etl-2",
            "update_resolution",
            { values: rejectedValues },
            "mars"
          )
          .then((response) => {
            self.loading = false;
            self.processingResults = response.data.rows;

            this.selectedResolution = null;
            this.selected = [];
            this.lastClickedItem = null;

            for (let row of self.processingResults) {
              self.resolutions = self.resolutions.filter((item) => {
                return (
                  item.sale_processing_issue_resolution_id !=
                  row.sale_processing_issue_resolution_id
                );
              });
            }
          })
          .catch(() => {
            self.loading = false;
          });
      }
      // first send all of the rejections

      // next send all approved to the warehouse master db
      if (approvedValues.length > 0) {
        self.loading = true;
        api
          .callDBFunc(
            "db-1",
            "handle_customer_mapping_failure",
            {
              values: approvedValues,
            },
            "sprout"
          )
          .then((response) => {
            self.updateResolutionResults(approvedValues, response.data.rows);

            api
              .callDBFunc(
                "etl-2",
                "update_resolution",
                { values: approvedValues },
                "mars"
              )
              .then((response) => {
                self.loading = false;
                self.processingResults = response.data.rows;

                this.selectedResolution = null;
                this.selected = [];
                this.lastClickedItem = null;

                for (let row of self.processingResults) {
                  self.resolutions = self.resolutions.filter((item) => {
                    return (
                      item.sale_processing_issue_resolution_id !=
                      row.sale_processing_issue_resolution_id
                    );
                  });
                }
              })
              .catch(() => {
                self.loading = false;
              });
          })
          .catch(() => {
            self.loading = false;
          });
      }
    },

    getPipelineName(item) {
      if (item.data?.sale_data?.pipeline_name) {
        return `${item.data.pipeline_id} - ${item.data.sale_data.pipeline_name}`;
      } else {
        return "";
      }
    },

    getUserInfo(item) {
      if (item.data?.email) {
        const user = item.data.email.split("@")[0] || "system";
        if (user.includes(".")) {
          return user.split(".")[0];
        } else {
          return user;
        }
      }

      return "";
    },

    getExternalCustomerID(item) {
      return item.data.sale_data.customer_id
        ? item.data?.sale_data?.customer_id
        : "";
    },

    getExternalCustomerName(item) {
      return item.data.sale_data.customer_name
        ? item.data?.sale_data?.customer_name
        : "";
    },

    getWarehouseID(item) {
      if (item.data.type === "add_customer_mapping") {
        return item.data?.mars_vendor_warehouse?.id
          ? item.data.mars_vendor_warehouse.id
          : "";
      } else {
        return "";
      }
    },

    getWarehouseName(item) {
      if (item.data.type === "add_customer_mapping") {
        return item.data?.mars_vendor_warehouse?.name
          ? item.data.mars_vendor_warehouse.name
          : "";
      } else {
        return item.data?.name ? item.data.name : "";
      }
    },

    getWarehouseMarketSegmentID(item) {
      if (item.data.type === "add_customer_mapping") {
        return item.data?.mars_vendor_warehouse?.market_segment_id?.key
          ? item.data.mars_vendor_warehouse.market_segment_id.key
          : "";
      } else {
        return item.data?.market_segment?.key
          ? item.data.market_segment.key
          : "";
      }
    },

    getWarehouseMarsDirectSalesTerritoryID(item) {
      if (item.data.type === "add_customer_mapping") {
        return item.data?.mars_vendor_warehouse?.mars_direct_sales_territory_id
          ?.key
          ? item.data.mars_vendor_warehouse.mars_direct_sales_territory_id.key
          : "";
      } else {
        return item.data?.mars_direct_sales_territory?.key
          ? item.data.mars_direct_sales_territory.key
          : "";
      }
    },

    getWarehouseSubgroupID(item) {
      if (item.data.type === "add_customer_mapping") {
        return item.data?.mars_vendor_warehouse?.buying_subgroup_id?.key
          ? item.data.mars_vendor_warehouse.buying_subgroup_id.key
          : "";
      } else {
        return item.data?.buying_subgroup?.key
          ? item.data.buying_subgroup.key
          : "";
      }
    },

    getWarehouseSubchannelID(item) {
      if (item.data.type === "add_customer_mapping") {
        return item.data?.mars_vendor_warehouse?.subchannel?.key
          ? item.data.mars_vendor_warehouse.subchannel_id.key
          : "";
      } else {
        return item.data?.subchannel_id?.key ? item.data.subchannel_id.key : "";
      }
    },
  },

  computed: {
    ResolutionDataAsString() {
      if (this.lastClickedItem) {
        return JSON.stringify(this.lastClickedItem.data, null, 4);
      }

      return "";
    },

    marketSegment() {
      return this.isCreateWarehouse() &&
        this.lastClickedItem.data.market_segment &&
        this.lastClickedItem.data.market_segment.key
        ? `${this.lastClickedItem.data.market_segment.key} - ${this.lastClickedItem.data.market_segment.value}`
        : "";
    },

    buyingSubgroup() {
      return this.isCreateWarehouse() &&
        this.lastClickedItem?.data?.buying_subgroup?.key
        ? `${this.lastClickedItem.data.buying_subgroup.key} - ${this.lastClickedItem.data.buying_subgroup.value}`
        : "";
    },

    distributor() {
      return this.isCreateWarehouse() &&
        this.lastClickedItem?.data?.mars_distributor?.key
        ? `${this.lastClickedItem.data.mars_distributor.key} - ${this.lastClickedItem.data.mars_distributor.value}`
        : "";
    },

    marsDirectSalesTerritory() {
      return this.isCreateWarehouse() &&
        this.lastClickedItem.data.mars_direct_sales_territory &&
        this.lastClickedItem.data.mars_direct_sales_territory.key
        ? `${this.lastClickedItem.data.mars_direct_sales_territory.key} - ${this.lastClickedItem.data.mars_direct_sales_territory.value}`
        : "";
    },

    subchannel() {
      return this.isCreateWarehouse() &&
        this.lastClickedItem.data.subchannel &&
        this.lastClickedItem.data.subchannel.key
        ? `${this.lastClickedItem.data.subchannel.key} - ${this.lastClickedItem.data.subchannel.value}`
        : "";
    },

    pendingSaleData() {
      return this.pendingSale ? this.pendingSale : {};
    },
  },

  mounted() {
    this.getResolutions();
    this.clearPendingSale();
  },
};
</script>

<style>
.highlightedRow td {
  background: #e65100 !important;
}
</style>
